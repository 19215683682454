<style>
    .btnCustom:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
</style>

<template>
    <v-btn :style="styleBtn" elevation="4" dark rounded :color="colorBtn" :disabled="disable" :to="to" v-on:click="eventC" :class="classBtn + ' btnCustom'">
            {{ textBtn }}
        <v-icon v-if="iconT.length > 2" right>
            mdi-{{iconT}}
        </v-icon>
    </v-btn>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
    name: 'btnRounded',
    props: {
        textBtn: String,
        colorBtn: String,
        iconT: {
            type: String,
            default: ""
        }, eventC: {
            type: Function,
            default: () => { }
        },
        classBtn: {
            type: String,
            default: ""
        }, 
        to:{
            type:Object
        },
        disable: {
            type:Boolean,
            default: false
        },
        styleBtn: {
            type: String,
            default: ""
        }, 
    },
    methods: {

    }
})
</script>
