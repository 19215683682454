//const ToolBarConfig = [];
import axios from "axios";
import store from "@/store";

const requestApi = async (
  opt: string,
  url: string,
  pdata?: any,
  stateLoading: boolean = true
) => {
  var resp;
  store.commit("Shared/stateLoading", stateLoading);
  try {
    await axios({
      headers: {
        Authorization: "bearer " + store.getters["Auth/getToken"],
        "Content-Type": "application/json",
      },
      method: opt,
      url: url,
      data: pdata,
    }).then((rs) => {
      resp = rs.data;
    });
  } catch (e: any) {
  } finally {
    store.commit("Shared/stateLoading", false);
  }
  return resp;
};

const rApiWHeader = async (
  opt: string,
  url: string,
  token: any,
  pdata?: any
) => {
  var resp;
  store.commit("Shared/stateLoading", true);
  try {
    await axios({
      headers: { Authorization: "bearer " + token },
      method: opt,
      url: url,
      data: pdata,
    }).then((rs) => {
      resp = rs.data;
    });
  } catch (e: any) {
  } finally {
    store.commit("Shared/stateLoading", false);
  }
  return resp;
};

export { requestApi, rApiWHeader };
