<template>
	<v-row justify="center">


		<div class="container">

			<v-row>
				<v-col cols="6" class="pr-0 pb-5 d-none d-md-none d-lg-inline">
					<v-card color="#323566" class="mt-10" min-width="500px" min-height="600px">
						<v-card class=" elevation-0 d-inline align-center" color="transparent">
							<v-img class="mx-auto" src="../../assets/PmsLoginBanner.png"  width="500px" height="600px">
							</v-img>

							<!-- <center>
								<v-divider class="fontWhite--text pa-2"
									style="border-color:white;width: 250px;"></v-divider>
							</center>
							<h1 class="text-center" style="color:white;font-size:2.2em;">Purdy
								Mobility <br> System</h1>
							<center>
								<small class="text-center" style="color:white;">By <strong>Grupo Purdy</strong></small>
							</center> -->
						</v-card>
					</v-card>
				</v-col>
				<v-col cols=" 6" class="pl-0">
					<v-card color="fontWhite" class="pb-10 mt-10" min-width="500px" min-height="600px">
						<br>
						<v-card-title class="mt-10 justify-center" max-width="300px">
							<!-- <h1 class="text-center menuColor--text pt-10" style="font-size:2.0em;">Ingresar a PMS</h1> -->
							<v-img class="mx-auto" src="../../assets/PmsWhiteLogo.png"  width="auto" height="100px">
							</v-img>
						</v-card-title>
						<v-card-text>
							<v-container class="align-items-center">
								<form id="myForm">
									<v-row justify="center">
										<v-col cols="8" sm="8" lg="8" md="8">
											<label for="username">Usuario</label>
											<v-text-field solo v-model="user.userName" label="Usuario" required
												color="menuColor" class="menuColor--text">
											</v-text-field>

											<label for="username">Contraseña</label>
											<v-text-field solo v-model="user.password"
												:type="showPassword ? 'text' : 'password'"
												@click:append="() => (showPassword = !showPassword)" label="Contraseña"
												:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" required
												v-on:keyup.enter="login">
											</v-text-field>

											<a @click="dgResetPassword = true">
												<p style="float:left;">
													Olvidaste tu contraseña?</p>
											</a>

										</v-col>
									</v-row>
									<v-row justify="end">

									</v-row>
									<v-row justify="center" style="padding: 20px 0">
										<btnRounded colorBtn="#323566" :eventC="login" textBtn="Ingresar">
										</btnRounded>
									</v-row>
								</form>
							</v-container>
						</v-card-text>

						<!-- <v-row>
							<v-col>
								<v-card class="justify-start elevation-0">
									<v-img class="mx-auto" src="../../assets/yovoy-gray.png" width="110"
										heigth="100"></v-img>
								</v-card>
							</v-col>
							<v-col>
								<v-card class="justify-end elevation-0">
									<v-img class="mx-auto" src="../../assets/tellevo-gray.png" width="110"
										heigth="100"></v-img>
								</v-card>
							</v-col>
						</v-row> -->

					</v-card>
				</v-col>
			</v-row>

		</div>

		<v-dialog v-model="dialogMessageShow" max-width="500px">
			<!-- <v-card>
				<v-card-title style="font-weight: 400;">{{ dialogMessageText }}</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<btnRounded colorBtn="primary" :eventC="closeDelete" textBtn="Entendido" iconT="check">
					</btnRounded>
				</v-card-actions>
			</v-card> -->
			<v-card color="white" light>
				<v-card-title class="text-h5">
					{{ dgTitleSms }}
				</v-card-title>

				<v-card-subtitle>
					{{ dialogMessageText }}
				</v-card-subtitle>

				<v-card-actions style="display:grid!important">
					<v-btn @click="closeDelete" class="float-right" color="menuColor white--text">
						Continuar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>


		<v-dialog v-model="dgResetPassword" persistent max-width="600px">

			<v-card>
				<v-card-title>
					<span class="text-h5">Solicitar nueva contraseña</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col cols="12" sm="12" md="12">
								<v-text-field label="Usuario" v-model="userReset" required></v-text-field>
							</v-col>

						</v-row>
					</v-container>
					<!-- <small>*indicates required field</small> -->
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<btnRounded colorBtn="error" :eventC="closeRecovery" textBtn="Cancelar">
					</btnRounded>
					<btnRounded colorBtn="success" :eventC="sendRecovery" textBtn="Enviar">
					</btnRounded>

				</v-card-actions>
			</v-card>
		</v-dialog>

	</v-row>

</template>



<script lang="ts">
import Vue, {
	ref
} from "vue";

import {
	mapMutations
} from "vuex";
import store from "@/store";

import btnRounded from "@/components/shared/BtnRounded.vue";

import { loginUser, getActionsUser, sendForgotPassword } from '@/services/authServices/authServices';

import { LoginUserI, ResponseActions } from "@/interfaces/authInterfaces";
import { ResponseData } from '../../interfaces/shared/response';


export default Vue.extend({
	name: 'LoginView',
	components: {
		btnRounded,
	},
	data() {
		return {
			user: {} as LoginUserI,
			userReset: "",
			dgTitleSms: "",
			dialogMessageShow: false,
			dialogMessageText: "",
			showPassword: false,
			dgResetPassword: false,

		};
	},

	mounted() {

	},
	methods: {
		closeDelete() {
			this.$data.dialogMessageShow = false;
			this.$data.dialogMessageText = "";
			this.$data.dgTitleSms = "";
			
		},
		async login() {
			if (this.$data.user.userName != "" && this.$data.user.password != "") {
				this.user.userName = this.user.userName.trim();
				this.user.password = this.user.password.trim();
				var resp = await loginUser(this.$data.user);

				if (resp.succeeded) {
					var actionsU = await getActionsUser(resp.data.userName, resp.data.jwToken) as ResponseActions;
					if (actionsU.succeeded) {
						//this.authUser();
						await store.commit('Auth/authUser', { user: JSON.stringify(resp.data), actionsU: JSON.stringify(actionsU.data), idToken: resp.data.jwToken });


						if(resp.data.roles.some(x => x == "Passenger")){
							this.$router.push({
								name: 'formLocationServicesD2D'
							});
						} else {
							this.$router.push({
								name: 'home'
							});
						}
					} else {
						this.$data.dialogMessageShow = true

						this.$data.dgTitleSms = "Acceso Inválido";
			
						this.$data.dialogMessageText = "Por favor revisa que tu <b>usuario y contraseña</b> sean correctos e intenta nuevamente."; //resp.message;
					}
				} else {
					this.$data.dgTitleSms = "Acceso Inválido";
					this.$data.dialogMessageShow = true
					this.$data.dialogMessageText = "Por favor revisa que tu <b>usuario y contraseña</b> sean correctos e intenta nuevamente."; //resp.message;
				}
			} else {
				this.$data.dialogMessageShow = true
				this.$data.dgTitleSms = "Acceso Inválido";
				this.$data.dialogMessageText = "Ingrese un usuario y contraseña";
			}
		},
		async sendRecovery() {
			if (this.$data.userReset != undefined && this.$data.userReset.length > 1) {
				var resp = await sendForgotPassword(this.$data.userReset) as ResponseData;
				this.$data.dialogMessageShow = true
				this.$data.dialogMessageText = resp.message;

				this.$data.dgTitleSms = resp.succeeded ? "Correcto" : "Error";
				if(resp.succeeded){
					this.closeRecovery();
				}
			}else{
				this.$data.dialogMessageShow = true
				this.$data.dialogMessageText = "Ingrese un usuario";

				this.$data.dgTitleSms = "Error";
			}
		},
		closeRecovery() {
			this.$data.userReset = "";
			this.$data.dgResetPassword = false;
		},
		...mapMutations('Auth', ['authUser'])
	},
	computed: {

	},
});
</script>

<style lang="scss" scoped>

</style>
<style>
#myForm .v-text-field--solo>.v-input__control>.v-input__slot {
	background-color: #3335664d !important;
}

#myForm .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
	box-shadow: none;
}

#myForm .v-input input,
#myForm .v-input textarea {
	color: #333566
}

#myForm .v-label {
	color: #333566
}

.v-image__image--cover {
    background-size: contain;
}
</style>