<template>
    <v-app :style="{ background: $vuetify.theme.themes[theme].appBackground }">

        <v-main>
            <!--<div v-if="authStatus.value === 'authenticating'">
            </div>
            <div v-else>-->
            <template>
                <router-view />
            </template>


            <!--</div>-->


        </v-main>
        <v-row justify="center">
            <v-overlay :value="isLoading" color="transparent">
                <v-card width="250" outlined color="transparent">
                    <v-card-text class="text-center">
                        <v-progress-circular size="140" :width="10" class="mr-1" indeterminate color="menuColor"></v-progress-circular>
                    </v-card-text>
                </v-card>
            </v-overlay>
        </v-row>
    </v-app>
</template>

<script lang="ts">
import Vue from "vue";

import MenuAppBar from "@/components/menuComponents/MenuAppBar.vue";
import Login from "@/views/authViews/LoginView.vue";
import {
    mapMutations
} from "vuex";
import { useAuth } from '@/composables';
import store from '@/store';


export default Vue.extend({
    setup() {

        const {
            authStatus,
            checkAuthStatus
        } = useAuth()


        const init = async () => {
            await checkAuthStatus()

        }

        init();

        return {
            authStatus
        }
    },
    name: "App",
    components: {
        MenuAppBar,
        Login
    },

    data() {
        return {
            // status: useAuth().authStatus
        }
    },

    computed: {
        theme() {
            return (this.$vuetify.theme.dark) ? 'dark' : 'light'
        },
        title() {
            return this.$store.state.Shared.titleDash;
        },
        isLoading() {
            return this.$store.state.Shared.isLoading;
        },
        isUserLogin() {
            return this.$store.state.Shared.isUserLogin
        },

    },
    methods: {
        setDrawerState() {
            this.stateDrawer(true)
        },
        setUserLogout() {
            this.userLogin(false)
            this.stateDrawer(false)


        },

        getUserLogin() {
            return (this.$store.state.Auth.idToken) ? true : false;
        },
        ...mapMutations('Shared', ['stateDrawer', 'userLogin'])
    },
    async beforeMount() {
        //console.log("check");
        const { ok } = await store.dispatch('Auth/checkAuthentication');
        //console.log(ok);
    },

});
</script>
